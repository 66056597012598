import React from 'react';
import Button from '../components/Button';
import Callout from '../components/Callout';
import { Column, Container, Row } from '../components/Grid';
import Hero from '../components/Hero';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Section from '../components/Section';

const IndexPage = ({ data }) => (
  <Layout title="Home">
    <div className="home-hero-and-callout">
      <Hero
        mediaSource="home-hero.png"
        className="mb-0 home-hero-and-callout__hero"
      >
        <Container noGutters className="home-hero-and-callout__hero__content">
          <Image
            filename="home-hero-cloud.png"
            className="home-hero-and-callout__hero__content__cloud"
          />
          <Image
            filename="home-hero-astronaut.png"
            className="home-hero-and-callout__hero__content__astronaut"
          />
          <Image
            filename="home-hero-stars.png"
            className="home-hero-and-callout__hero__content__stars"
          />
          <Image
            filename="home-hero-programLogo@2x.png"
            className="home-hero-and-callout__hero__content__title"
          />
          <Image
            filename="home-hero-planets.png"
            className="home-hero-and-callout__hero__content__planets"
          />
        </Container>
      </Hero>
      {/* <a href="https://surveys.discoveryeducation.com/s3/DG-Discover-Literacy-Program-Survey" target="_blank">
      <Callout >
        Have a few minutes? We would love to hear your feedback about Discover Literacy!
      </Callout>
      </a> */}
      <Image
        filename="home-building-cloud.png"
        className="home-building-literacy__image"
      />
    </div>
    <Section className="home-building-literacy">
      <Container className="home-building-literacy__container">
        <div className="home-building-literacy__container__intro-text">
          <Row>
            <Column size={10}>
              <h1>Building Literacy into Every School Day</h1>
              <p className="large">
                Engage students with living literacy explorations that are
                flexibly designed to fit easily into your busy schedule.
              </p>
            </Column>
          </Row>
        </div>
        <Row>
          <Column
            size={6}
            className="home-building-literacy__container__left-column"
          >
            <Image
              filename={'home-img-girlreading.jpg'}
              alt="Girl reading a book"
            />
            <h2 className="mt-1">Level-Up with Literacy Microlearnings</h2>
            <p>
              Microlearnings are short and easy-to-use, allowing educators to
              access professional learning resources at their convenience.
            </p>
            <Button to="/classroom-resources" className="mt-1">
              Learn More
            </Button>
          </Column>
          <Column
            size={6}
            className="home-building-literacy__container__right-column"
          >
            <Image
              filename={'home-img-readingtokids.jpeg'}
              alt="Woman reading to children"
            />
            <h2 className="mt-1">Literacy Connections in the Classroom</h2>
            <p>
              Access free resources and materials to support the application of
              the microlearnings in an engaging and easily differentiated
              format.
            </p>
            <Button to="/classroom-resources" className="mt-1">
              Learn More
            </Button>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="home-about">
      <Container className="home-about__container">
        <div className="home-about__container__yellow-bar"></div>
        <div className="home-about__container__blue-arrow">
          <Image
            className="home-about__container__blue-arrow--right"
            filename="home-blueArrow.svg"
          />
          <Image
            className="home-about__container__blue-arrow--up"
            filename="home-blueArrowUp.svg"
          />
        </div>
        <div className="home-about__container__text">
          <h1>About the Program</h1>
          <p>
            To help students develop the knowledge, skills, and experiences they
            must have to become competent and enthusiastic readers, the Dollar
            General Literacy Foundation, Discovery Education, and the National
            Afterschool Association have launched a nationwide literacy
            initiative to upskill teachers with research-based supports and
            turnkey resources that fit into their busy instructional day.
          </p>
          <p>
            Discover new ways to approach literacy education with flexible,
            open-ended learning resources designed for K–2 classrooms.
          </p>
          <Button to="/about">Learn More</Button>
        </div>
        <Image
          filename="home-handsAndLetters.jpg"
          className="home-about__container__right-image"
        />
      </Container>
    </Section>
    <Section className="home-quote">
      <Container className="home-quote__container">
        <Row>
          <Column size={2} className="home-quote__container__balloons-column">
            <Image filename="home-ABCballoons.svg" />
          </Column>
          <Column size={8} className="home-quote__container__text-column">
            <h1 className="pt-2">
              In 2022, 54% of adults had a literacy below 6th grade level.
            </h1>
            <p className="large pt-1 pb-2">&#8212;US Department of Education</p>
          </Column>
          <Column size={2} className="home-quote__container__astronaut-column">
            <Image filename="home-astronaut.svg" />
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default IndexPage;
